/**=====================
    57. breadcrumb CSS Start
==========================**/
.breadcrumb-colored{
	li{
		a{
			color:$white;
		}
		&.active{
			color: $white;
			opacity:0.7;
		}
	}
	.breadcrumb-item{
		&:before{
			color:$white;
		}
	}
	.breadcrumb-item.txt-dark{
		&:before{
			color:$white;
		}
	}
}
.breadcrumb-item{
	&.active{
		text-transform: capitalize;
	}
}
.bread-custom{background-color:$dark-purple;color:$white}

/**=====================
    57. breadcrumb CSS ends
==========================**/
