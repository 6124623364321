.topbar{background-color:$danger-color;
    padding: 10px;
    color: $white;}
.tab-content{background:$white ;font-size:15px;}    
.fund-main .form-control {
    border-radius: 5px;
    font-size: 12px;
}
.fund-main .search {
    border: 1px solid #000;
    line-height: 29px;
    width: 12%;
    background: $white;
    border-radius: 5px;
    margin-left: 4px;
}

.excel {
    border: 1px solid green;
    line-height: 32px;
    width: 12%;
    background:$white;
    border-radius: 5px;
}
.fund-main{background:$white ;
    font-size: 15px;
    margin-top: 1rem;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #e0e6ed;
    box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);}

    .fund-main h2{letter-spacing: 0px;
        font-size: 19px;
        display: block;
        color:$dark-purple;
        margin-bottom: 0;}


        .fund-main h5{letter-spacing: 0px;
            font-size: 13px;
            display: block;
            color:$dark-color;
            margin-bottom: 0;}