.customTbl {
    /* overflow: auto; */
    border-radius: 7px;
    margin-top: 10px;
}

.customTbl>div {
    padding: 10px;
}

.customTblHover tbody tr:hover {
    transform: translateY(-1px) scale(1.01);
    background-color: #f1f2f3 !important;
    transition: all 0.1s ease;
}

.customTbl .customTblFilterHeaderBox {
    position: sticky;
    top:80px;
    z-index: 1;
}
.ltr .customTbl .customTblFilterHeaderBox {
    position: sticky;
    top: 0;
    z-index:0;
}
.customTbl thead {
    position: sticky;
    top: 0;
}

.dtSearch {
    padding: 10px!important;
    width: 250px!important;
    height: 45px!important;
    border-radius: 40px;
    border:1px solid #405bf2;
}

.customTbl tbody tr td {
    border-bottom:1px dashed #8b90af;
}


/* ------------------------TABLE TOP ACTION SECTION START-------------------------------- */

.customTblFilterHeaderBox {
    display: flex;
    background-color:#b7cee4;
    flex-wrap: wrap;
    padding: 10px;
}

.customTblFilterHeaderBox>div {
    display: inline-flex;
    align-items: center;
    margin: 0px 20px 0px 0;
}

.customTblFilterHeaderBox>div:nth-last-child(1),
.customTblFilterHeaderBox>div:nth-last-child(2) {
    margin-right: 0px;
}

.customTblFilterHeaderBox>div:nth-last-child(1) {
    margin-left: auto;
}

.customTblFilterHeaderBox>div:nth-last-child(1)>div span {
    display: inline-flex;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 5px hsla(0, 0%, 0%, 0.15) !important;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    background: #07176c;
}

.customTblFilterHeaderBox>div>span {
    margin-right: 15px;
    color: #132587;
    font-size: 17px;
    font-weight: 600;
}

.customTblFilterHeaderBox>div>.form-group {
    margin-bottom: 0px;
}

.customTblFilterHeaderBox>div>.form-group select {
    height: 45px;
    padding: 0.15rem 0.45rem;
    max-width: 150px;
    /* margin-bottom: 5px; */
}


/* ------------------------TABLE TOP ACTION SECTION END-------------------------------- */


/* ------------------------TABLE SECTION START-------------------------------- */

.customTblBox {
    overflow-x: auto;
}

.customTblBox table {
    width: 100%;
    margin-bottom: 10px;
    /* min-width: 1220px; */
}

.customTblBox table tr th {
    white-space: nowrap;
    text-transform: uppercase;
}

.customTblBox table tr th,
.customTblBox table tr td {
    padding :12px;
}

.customTblBox table thead tr {
    color: #3e3c3d;
    background-color:hsl(56deg 28% 81%);
    padding: 10px;
}

.customTblBox table tbody tr {
    height: 50px;
    color:#000000;
}


/* ------------------------TABLE SECTION END-------------------------------- */


/* ------------------------TABLE PAGINATION SECTION START-------------------------------- */

.dtPagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.dtPagination a {
    margin: 0 10px;
    font-size: 18px;
    color: #000;
}

.pagerMute {
    opacity: 0.5;
    cursor: not-allowed !important;
}


/* ------------------------TABLE PAGINATION SECTION END-------------------------------- */


/* ------------------------------------------------- */


/* ------------------------TRACK TABLE DESIGN START-------------------------------- */


/* ---------------TRACK TABLE DESIGN 1 START(TABLE STRIPE WITH FIRST COLUMN DOT)--------------- */

.customTblBox.tblStripeWithFCD table tr th:first-child,
.customTblBox.tblStripeWithFCD table tr td:first-child {
    padding-left: 40px;
}


/* .customTblBox.tblStripeWithFCD table tbody tr td:nth-child(2),
.customTblBox.tblStripeWithFCD table tbody tr td:nth-child(3) {
    font-weight: 600;
} */

.customTblBox.tblStripeWithFCD table tbody tr:nth-child(2n-2) {
    background-color: hsla(0, 30.3%, 87.1%, 0.24);
}

.customTblBox.tblStripeWithFCD table tr td:first-child {
    position: relative;
}

.customTblBox.tblStripeWithFCD table tr td:first-child::after {
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    bottom: 0;
    margin: auto;
}

.customTblBox.tblStripeWithFCD table tbody tr:nth-child(2n-1) td:first-child::after {
    background-color: #ff6e4f;
}

.customTblBox.tblStripeWithFCD table tbody tr:nth-child(2n-2) td:first-child::after {
    background-color: #1de4e4;
}

.customTblBox.tblStripeWithFCD table tbody tr:hover {
    background-color: rgb(226, 205, 205);
    cursor: pointer;
}

.customTblBox.tblStripeWithFCD table tbody tr:hover td:first-child::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0px;
    width: 3px;
    height: 100%;
    bottom: 0;
    margin: auto;
    background-color: #3123ae;
}


/* ---------------TRACK TABLE DESIGN 1 END--------------- */


/* ------------------------TRACK TABLE DESIGN END-------------------------------- */


/* //////////////////////REFRESH RELOAD START/////////////////////////////// */

.syncBtn {
    display: flex;
    background-color: #000;
    width: 35px;
    height: 35px;
    box-sizing: border-box;
    color: #fff;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.syncBtn.rotateIt i {
    animation: rotation 1s infinite;
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}


/* //////////////////////REFRESH RELOAD END/////////////////////////////// */
